<script setup>
import { MCol, MSkeleton } from '@ca-crowdfunding/makuake-ui-n';
import ScrollContainer from '@/components/home/ScrollContainer';
import SectionHeader from '@/components/home/SectionHeader';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useClickTracking from '@/composables/clickTracking';
import useFetchProjects from '@/composables/home/fetchProjects';
import useIntersection from '@/composables/home/intersection';

const { eventCategories, makeEventLabel, pushGtmClickEvent } =
  useClickTracking();
const { fetchWatchedProjects, isLoading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchWatchedProjects);
</script>

<template>
  <section v-if="projects.length" class="history-section pb-10 sm:pb-12">
    <SectionHeader
      :click-tracking-category="eventCategories.home"
      :click-tracking-label="makeEventLabel('watched', 'more')"
      href="/favorite/watched/"
      title="閲覧履歴"
    />
    <ScrollContainer gap="2" gap-sm="3" tag="ul">
      <template v-if="isLoading">
        <MCol v-for="key in 6" :key class="min-w-min" cols="2" tag="li">
          <MSkeleton aspect-ratio="16 / 9" rounded />
        </MCol>
      </template>
      <MCol
        v-for="(project, key) in projects"
        v-else
        :key
        class="min-w-min"
        cols="2"
        tag="li"
      >
        <a
          class="block min-w-28"
          :href="`/project/${project.code}/`"
          @click="
            pushGtmClickEvent(
              eventCategories.home,
              makeEventLabel('watched', 'item', key),
            )
          "
        >
          <ThumbnailCard
            :alt="project.title"
            aspect-ratio="16 / 9"
            height-sm="107"
            height="63"
            :src="project.staticurl_static_cdn + project.mainimage_path_cdn"
            width-md="190"
            width="112"
          />
        </a>
      </MCol>
    </ScrollContainer>
  </section>
</template>

<style scoped>
.min-w-min {
  min-width: min-content;
}

.min-w-28 {
  min-width: 7rem;
}
</style>
