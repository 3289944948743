<script setup>
import { register } from 'swiper/element/bundle';
import { nextTick, onBeforeMount, onUpdated, ref } from 'vue';
import BannerItem from '@/components/home/BannerItem';
import HeroNavigation from '@/components/home/HeroNavigation';
import api from '@/modules/api/v2/heroes';

import 'swiper/element/css/mousewheel';

// Swiper のカスタム要素を登録
register();

const id = 'banner-container';
const prevClass = 'banner-prev';
const nextClass = 'banner-next';

const swiperParams = {
  a11y: {
    // ナビゲーションのaria-controls属性用に必要
    id,
  },
  breakpoints: {
    640: {
      spaceBetween: 24,
    },
  },
  centeredSlides: true,
  centerInsufficientSlides: true,
  loop: true,
  mousewheel: {
    // マウスホイール操作は水平方向のみ有効にする
    forceToAxis: true,
  },
  navigation: {
    nextEl: `.${nextClass}`,
    prevEl: `.${prevClass}`,
  },
  slidesPerView: 'auto',
  spaceBetween: 12,
  speed: 400,
  threshold: 16,
};

const banners = ref([]);

const fetchBanners = async () => {
  const res = await api.fetchBanners();
  if (res.data?.heroes?.length) banners.value = res.data.heroes;
};

onBeforeMount(fetchBanners);

const initSwiper = () => {
  const swiperEl = document.getElementById(id);
  if (swiperEl) {
    // 取得した件数に応じてループを無効化する
    if (banners.value.length) {
      const width = Math.max(1, 320 * (banners.value.length - 2));
      const noLoop = {
        centeredSlides: false,
        loop: false,
        slidesOffsetAfter: 24,
        slidesOffsetBefore: 24,
        spaceBetween: width < 640 ? 12 : 24,
      };

      if (width <= 1920) {
        swiperParams.breakpoints[width] = {
          ...swiperParams.breakpoints[width],
          ...noLoop,
        };
      }

      if (width <= 640) {
        swiperParams.breakpoints[640] = {
          ...swiperParams.breakpoints[640],
          ...noLoop,
        };
      }
    }
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }
};

onUpdated(() => {
  nextTick(() => {
    initSwiper();
  });
});
</script>

<template>
  <div v-if="banners.length" class="bg-tertiary py-4 mb-4 sm:mb-6">
    <div class="banner-area">
      <swiper-container :id="id" init="false">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <BannerItem :banner="banner" :index="index" />
        </swiper-slide>
      </swiper-container>
      <HeroNavigation :next-class="nextClass" :prev-class="prevClass" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner-area {
  margin-inline: auto;
  max-width: 1920px;
  position: relative;
}

::part(container) {
  overflow: visible;
}

swiper-slide {
  max-width: calc(100% - 2rem);
  width: 240px;
}

@media (min-width: 640px) {
  swiper-slide {
    width: 320px;
  }
}
</style>
