<script setup>
import { register } from 'swiper/element/bundle';
import { nextTick, onBeforeMount, onUpdated, ref } from 'vue';
import HeroItem from '@/components/home/HeroItem';
import HeroNavigation from '@/components/home/HeroNavigation';
import api from '@/modules/api/v2/heroes';

import 'swiper/element/css/autoplay';
import 'swiper/element/css/mousewheel';
import 'swiper/element/css/pagination';

// Swiper のカスタム要素を登録
register();

const id = 'hero-container';
const prevClass = 'hero-prev';
const nextClass = 'hero-next';

const swiperParams = {
  a11y: {
    // ナビゲーションのaria-controls属性用に必要
    id,
  },
  autoplay: {
    delay: 4000,
    // ユーザー操作後は自動再生を停止する
    // Swiperの仕様と実装で初期値が異なるので不具合の可能性が高く、
    // 改善されるようであれば削除する
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    640: {
      pagination: {
        clickable: true,
      },
      spaceBetween: 24,
    },
  },
  centerInsufficientSlides: true,
  centeredSlides: true,
  loop: true,
  mousewheel: {
    // マウスホイール操作は水平方向のみ有効にする
    forceToAxis: true,
  },
  navigation: {
    nextEl: `.${nextClass}`,
    prevEl: `.${prevClass}`,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  slidesPerView: 'auto',
  spaceBetween: 16,
  speed: 400,
  threshold: 16,
};

const heroes = ref([]);

const fetchHeroes = async () => {
  const res = await api.fetchProjects();
  if (res.data?.heroes?.length) heroes.value = res.data.heroes;
};

onBeforeMount(fetchHeroes);

const initSwiper = () => {
  const swiperEl = document.getElementById(id);
  if (swiperEl) {
    // 取得した件数に応じてループを無効化する
    if (heroes.value.length) {
      const width = Math.max(1, 560 * (heroes.value.length - 1));
      const noLoop = {
        centeredSlides: false,
        loop: false,
        pagination: false,
        slidesOffsetAfter: 24,
        slidesOffsetBefore: 24,
        spaceBetween: width < 640 ? 16 : 24,
      };

      if (width <= 1920) {
        swiperParams.breakpoints[width] = {
          ...swiperParams.breakpoints[width],
          ...noLoop,
        };
      }

      if (width <= 640) {
        swiperParams.breakpoints[640] = {
          ...swiperParams.breakpoints[640],
          ...noLoop,
        };
      }
    }
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }
};

onUpdated(() => {
  nextTick(() => {
    initSwiper();
  });
});
</script>

<template>
  <div v-if="heroes.length" class="hero-area pb-4 sm:pb-8">
    <swiper-container :id="id" init="false">
      <swiper-slide
        v-for="(hero, index) in heroes"
        :key="index"
        class="mb-6 sm:mb-8"
      >
        <HeroItem :hero="hero" :index="index" />
      </swiper-slide>
    </swiper-container>
    <HeroNavigation :next-class="nextClass" :prev-class="prevClass" />
    <div class="swiper-pagination" />
  </div>
</template>

<style lang="scss" scoped>
.hero-area {
  margin-inline: auto;
  max-width: 1920px;
  position: relative;
}

::part(container) {
  overflow: visible;
}

swiper-slide {
  height: auto;
  max-width: calc(100% - 2rem);
  width: 320px;
}

.swiper-pagination {
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #222;
  --swiper-pagination-bullet-inactive-opacity: 0.16;
  --swiper-pagination-bullet-size: 0.375rem;
  --swiper-pagination-color: #222;
  --swiper-pagination-bullet-horizontal-gap: 0.25rem;

  align-items: center;
  display: flex;
  justify-content: center;
  position: static;
}

@media (min-width: 640px) {
  :deep(.swiper-button-prev),
  :deep(.swiper-button-next) {
    top: calc(560px / 16 * 9 / 2);
  }

  .swiper-pagination {
    --swiper-pagination-bullet-size: 0.5rem;
  }

  swiper-slide {
    width: 560px;
  }
}
</style>
