<script setup>
import { onBeforeMount, provide, ref } from 'vue';
import {
  MCol,
  MContainer,
  MDivider,
  MRow,
  MSnackbar,
} from '@ca-crowdfunding/makuake-ui-n';
import FriendInviteTicketAcquireModal from '@/components/friendinvite/FriendInviteTicketAcquireModal';
import ActivityReportSection from '@/components/home/ActivityReportSection';
import ApplicationSection from '@/components/home/ApplicationSection';
import BannerArea from '@/components/home/BannerArea';
import BlogSection from '@/components/home/BlogSection';
import CatchCopy from '@/components/home/CatchCopy';
import CategorySection from '@/components/home/CategorySection';
import ComingSoonSection from '@/components/home/ComingSoonSection';
import EventSection from '@/components/home/EventSection';
import HeroArea from '@/components/home/HeroArea';
import HistorySection from '@/components/home/HistorySection';
import IntroductionSection from '@/components/home/IntroductionSection';
import NewArrivalsSection from '@/components/home/NewArrivalsSection';
import PickedUpSection from '@/components/home/PickedUpSection';
import PopularSection from '@/components/home/PopularSection';
import RankingSection from '@/components/home/RankingSection';
import RecommendedSection from '@/components/home/RecommendedSection';
import RemindedSection from '@/components/home/RemindedSection';
import ServiceSection from '@/components/home/ServiceSection';
import TagSection from '@/components/home/TagSection';
import TaggedSection from '@/components/home/TaggedSection';
import useLikedProjectIds from '@/composables/likedProjectIds';
import { TAGS } from '@/consts/home';
import meApi from '@/modules/api/php/me';
import UA from '@/modules/UserAgent';
import store from '@/store';

const device = ref('pc');
provide('device', device);

const snackbar = ref(false);
const snackbarMessage = ref('');
const snackbarActionLabel = ref('');
const snackbarAction = ref(() => {});
provide('snackbar', snackbar);
provide('snackbarMessage', snackbarMessage);
provide('snackbarActionLabel', snackbarActionLabel);
provide('snackbarAction', snackbarAction);

onBeforeMount(async () => {
  const { loadLikedProjectIds } = useLikedProjectIds();

  const res = await meApi.fetchIsLogin();
  const isLoggedIn = res.data?.is_logined ?? false;
  store.setIsLogin(isLoggedIn);
  await loadLikedProjectIds(isLoggedIn);

  const ua = new UA();
  await ua.init();

  if (ua.isSP()) {
    device.value = 'sp';
  }

  if (ua.isNative()) {
    device.value = 'app';
    return;
  }

  const { braze } = window;

  if (braze) {
    braze.logCustomEvent('pageview_top', { timestamp: new Date() });
  }
});
</script>

<template>
  <main id="wrapper">
    <CatchCopy />
    <HeroArea />
    <BannerArea />
    <MContainer>
      <CategorySection />
      <HistorySection />
      <RemindedSection />
      <RecommendedSection />
      <PickedUpSection />
      <RankingSection />
      <NewArrivalsSection />
      <ComingSoonSection />
      <PopularSection />
      <TaggedSection :tag="TAGS.MADE_IN_JAPAN" />
      <TaggedSection :tag="TAGS.FOOD" />
      <TaggedSection :tag="TAGS.UTILITY_GOODS" />
      <TaggedSection :tag="TAGS.GADGET" />
      <TaggedSection :tag="TAGS.FASHION" />
      <TaggedSection :tag="TAGS.OUTDOOR" />
      <ApplicationSection />
      <TagSection />
      <div class="pb-10 sm:pb-12">
        <MRow gap="10">
          <MCol cols="12" md="6"><ActivityReportSection /></MCol>
          <MCol cols="12" md="6"><BlogSection /></MCol>
        </MRow>
      </div>
      <EventSection />
      <IntroductionSection />
      <ServiceSection />
    </MContainer>
    <MDivider color="secondary" />
    <FriendInviteTicketAcquireModal />
    <MSnackbar
      v-model="snackbar"
      color="danger"
      :action-label="snackbarActionLabel"
      @action="snackbarAction"
    >
      {{ snackbarMessage }}
    </MSnackbar>
  </main>
</template>

<style>
html {
  font-size: 100%;
}

#wrapper {
  background: transparent;
  overflow: hidden;
}
</style>
