<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/home/fetchProjects';
import useIntersection from '@/composables/home/intersection';

const { fetchPickedUpProjects, isLoading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchPickedUpProjects);
</script>

<template>
  <ProjectSectionBase
    href="/discover/pickup"
    label="pickup"
    :loading="isLoading"
    :projects
    title="今日のピックアップ"
  />
</template>
