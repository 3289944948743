<script setup>
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import SectionHeader from '@/components/home/SectionHeader';
import useClickTracking from '@/composables/clickTracking';
import useEnvDefinition from '@/composables/envDefinition';
import useLink from '@/composables/link';

const { eventCategories, makeEventLabel } = useClickTracking();
const { env } = useEnvDefinition();
const { resolveLink } = useLink();

const cdn = env.URL_MAKUAKE_CDN;

const services = [
  {
    href: env.MAKUAKE_STORE_URL,
    name: 'Makuake STORE',
    slug: 'Store',
    text: 'Makuake発のユニークなアイデアがMakuake STOREで商品化。買い逃したあのプロジェクトにまた、出会えるかも。',
  },
  {
    href: 'https://magazine.makuake.com/',
    name: 'Makuake Magazine',
    slug: 'Magazine',
    text: 'アタラシイを生み出すための挑戦と応援をつなぐメディア。',
  },
  {
    href: 'https://mis.makuake.com/',
    name: 'MIS',
    slug: 'MIS',
    text: 'インキュベーションに「出口」を。Makuakeが伴走する新商品・新規事業開発。',
  },
];
</script>

<template>
  <aside class="partner-section pb-12 sm:pb-18">
    <SectionHeader heading-tag="h2" title="Makuakeのサービス" />
    <MRow gap="6">
      <MCol
        v-for="(service, index) of services"
        :key="index"
        cols="12"
        md="4"
        sm="6"
      >
        <a
          class="block"
          :href="service.href"
          :data-click-tracking-category="eventCategories.home"
          :data-click-tracking-label="makeEventLabel('services', 'item', index)"
          :target="resolveLink(service.href).target"
        >
          <article>
            <h3>
              <picture>
                <source
                  height="144"
                  media="(min-width: 960px)"
                  :srcset="`${cdn}img/home/bn_${service.slug}_pc.webp 1x, ${cdn}img/home/bn_${service.slug}_pc@2x.webp 2x`"
                  width="384"
                />
                <img
                  alt="Makuakeはじめてガイド"
                  class="mb-4 w-full"
                  height="124"
                  loading="lazy"
                  :src="`${cdn}img/home/bn_${service.slug}_sp.webp`"
                  width="366"
                />
              </picture>
            </h3>
            <MText
              max-lines="3"
              size="x-small"
              sm="small"
              tag="h4"
              weight="bold"
            >
              {{ service.text }}
            </MText>
          </article>
        </a>
      </MCol>
    </MRow>
  </aside>
</template>
