import { inject, ref, provide } from 'vue';
import {
  LIKED_PROJECT_IDS_KEY,
  IS_LIKED_PROJECT_IDS_LOADED_KEY,
} from '@/consts/home/likedProjectIds';
import api from '@/modules/api/php/good';

export default function useLikedProjectIds() {
  let likedIds = inject(LIKED_PROJECT_IDS_KEY, null);
  let isLoaded = inject(IS_LIKED_PROJECT_IDS_LOADED_KEY, null);
  if (!likedIds) {
    likedIds = ref(/** @type {number[]} */ []);
    isLoaded = ref(false);
    provide(LIKED_PROJECT_IDS_KEY, likedIds);
    provide(IS_LIKED_PROJECT_IDS_LOADED_KEY, isLoaded);
  }

  /**
   * @returns {Promise<void>}
   */
  const fetchIds = async () => {
    try {
      const res = await api.fetchGoods();
      if (res.data?.goods) {
        likedIds.value = res.data.goods.split(',').map(Number);
      } else if (res.data?.liked_project_ids) {
        likedIds.value = res.data.liked_project_ids;
      } else {
        likedIds.value = [];
      }
    } catch (_) {
      likedIds.value = [];
    } finally {
      isLoaded.value = true;
    }
  };

  const loadIdsFromCookie = () => {
    const cookieValue = api.getIds();
    // string[]なのでnumber[]に変換する
    likedIds.value = cookieValue.map(Number);
    isLoaded.value = true;
  };

  /**
   * @param {boolean} isLoggedIn
   */
  const loadLikedProjectIds = async isLoggedIn => {
    if (isLoggedIn) {
      await fetchIds();
    } else {
      loadIdsFromCookie();
    }
  };

  return { loadLikedProjectIds };
}
