<script setup>
import { computed } from 'vue';
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/home/LikeButton';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useClickTracking from '@/composables/clickTracking';
import useReturnPrice from '@/composables/home/returnPrice';
import { RETURN_SUSPENDED_TEXT } from '@/consts/home';

const props = defineProps({
  clickTrackingCategory: {
    type: String,
    required: true,
  },
  clickTrackingLabel: {
    type: String,
    required: true,
  },
  project: {
    type: Object,
    default: null,
  },
});

const { pushGtmClickEvent, parseEventLabel } = useClickTracking();

const clickTrackingLabelObject = computed(() =>
  parseEventLabel(props.clickTrackingLabel),
);

// リアクティブにすることでリターン価格が更新されるようにする
const returns = computed(() => props.project?.returns);
const { minPrice, hasPriceText } = useReturnPrice(returns);
</script>

<template>
  <MCol v-if="project" class="project-item min-w-min mb-3" cols="3" tag="li">
    <a
      class="block h-full min-w-56"
      :href="project.url"
      @click="pushGtmClickEvent(clickTrackingCategory, clickTrackingLabel)"
    >
      <article class="flex flex-col h-full">
        <ThumbnailCard
          alt=""
          aspect-ratio="16 / 9"
          class="mb-4"
          height-md="159"
          height="126"
          :src="project.image_url"
          width-md="282"
          width="224"
        >
          <template #text>
            <slot name="thumbnail.append" />
          </template>
        </ThumbnailCard>
        <MRow class="grow" gap="2" gap-sm="3" nowrap>
          <MCol v-if="project.user?.image_url" class="shrink-0">
            <OwnerAvatar
              :alt="project.user.name"
              :is-recommended-owner="project.user.is_selected_user"
              size="32"
              sm="40"
              :src="project.user.image_url"
            />
          </MCol>
          <MCol class="flex flex-col w-full" gap="0">
            <slot>
              <MText
                class="mb-3 sm:mb-4 last:mb-0"
                max-lines="3"
                size="x-small"
                sm="small"
                tag="h4"
                weight="bold"
              >
                {{ project.title }}
              </MText>
              <MRow
                align="center"
                class="mt-auto mb-2 last:mb-0"
                gap="0"
                justify="between"
              >
                <MCol class="flex">
                  <template v-if="hasPriceText">
                    <!-- TODO: ABテスト終了後元に戻す -->
                    <div class="px-2155-ab mt-auto mb-3 sm:mb-3.5 last:mb-0">
                      <MText
                        class="hidden"
                        size="x-small"
                        sm="small"
                        weight="bold"
                      >
                        <span class="text-secondary">総額</span>
                        {{
                          project.collected_money?.toLocaleString('ja-JP', {
                            currency: 'JPY',
                            style: 'currency',
                          })
                        }}
                      </MText>
                      <MText
                        v-if="minPrice"
                        size="x-small"
                        sm="small"
                        weight="bold"
                      >
                        {{
                          minPrice.toLocaleString('ja-JP', {
                            currency: 'JPY',
                            style: 'currency',
                          })
                        }}〜
                      </MText>
                      <MText
                        v-else
                        color="secondary"
                        class="mt-auto mb-3 sm:mb-3.5 last:mb-0"
                        size="x-small"
                        sm="small"
                        weight="bold"
                      >
                        {{ RETURN_SUSPENDED_TEXT }}
                      </MText>
                    </div>
                  </template>
                </MCol>
                <MCol class="flex">
                  <LikeButton
                    :click-tracking-category="clickTrackingCategory"
                    :click-tracking-label-module="
                      clickTrackingLabelObject.module
                    "
                    :click-tracking-label-position="
                      clickTrackingLabelObject.position
                    "
                    size="small"
                    sm="medium"
                    :project-id="project.id"
                  />
                </MCol>
              </MRow>
              <slot name="bottom" />
            </slot>
            <slot name="append" :project />
          </MCol>
        </MRow>
      </article>
    </a>
  </MCol>
</template>

<style scoped>
.min-w-min {
  min-width: min-content;
}

.min-w-56 {
  min-width: 14rem; /* 224px */
}
</style>
