<script setup>
import { ref } from 'vue';
import ProjectItem from '@/components/home/ProjectItem';
import ProjectItemLoader from '@/components/home/ProjectItemLoader';
import ScrollContainer from '@/components/home/ScrollContainer';
import SectionHeader from '@/components/home/SectionHeader';
import useClickTracking from '@/composables/clickTracking';

defineProps({
  containerTag: { type: String, default: 'ul' },
  href: { type: String },
  projects: { type: Array },
  label: { type: String },
  loading: { type: Boolean, default: false },
  show: { type: Boolean, default: false },
  title: { type: String },
});

const container = ref(null);
const { eventCategories, makeEventLabel } = useClickTracking();
</script>

<template>
  <section
    v-if="show || projects.length"
    :class="`${label}-section pb-10 sm:pb-12`"
  >
    <SectionHeader
      :click-tracking-category="eventCategories.home"
      :click-tracking-label="makeEventLabel(label, 'more')"
      :href
      :scroll-container="container"
      :title
    >
      <template v-if="$slots[`title.append`]" #default>
        <slot name="title.append" />
      </template>
    </SectionHeader>
    <ScrollContainer ref="container" :tag="containerTag">
      <template v-if="loading">
        <ProjectItemLoader v-for="key in projects.length" :key />
      </template>
      <ProjectItem
        v-for="(project, index) in projects"
        v-else
        :key="project.id"
        :click-tracking-category="eventCategories.home"
        :click-tracking-label="makeEventLabel(label, 'item', index)"
        :project
      >
        <template #[`thumbnail.append`]>
          <slot name="project.thumbnail.append" :project />
        </template>
        <template #append>
          <slot name="project.append" :project />
        </template>
      </ProjectItem>
    </ScrollContainer>
    <slot />
  </section>
</template>
