<script setup>
import { computed, inject } from 'vue';
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/home/LikeButton';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useReturnPrice from '@/composables/home/returnPrice';
import useClickTracking from '@/composables/clickTracking';
import { RETURN_SUSPENDED_TEXT } from '@/consts/home';

const props = defineProps({
  hero: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const device = inject('device');
const prefix = computed(() => (device.value !== 'pc' ? 'sp_' : ''));
const parameterizedUrl = computed(
  () => `${props.hero.url}?from=${prefix.value}hero_image_${props.index + 1}`,
);

const { minPrice, hasPriceText } = useReturnPrice(props.hero?.project?.returns);
const { eventCategories, makeEventLabel } = useClickTracking();
const clickTrackingCategory = computed(() => eventCategories.value.home);
const clickEventLabelModule = 'hero';
</script>

<template>
  <a
    class="hero-item block h-full"
    :href="parameterizedUrl"
    :target="hero.target"
    tabindex="-1"
    :data-click-tracking-category="clickTrackingCategory"
    :data-click-tracking-label="
      makeEventLabel(clickEventLabelModule, 'thumbnail', index)
    "
  >
    <article class="flex flex-col h-full">
      <ThumbnailCard
        alt=""
        aspect-ratio="16 / 9"
        class="mb-4 sm:mb-6"
        eager
        height-sm="315"
        height="180"
        :src="hero.thumbnail_image_url"
        width-sm="560"
        width="320"
      />
      <MRow class="grow" gap="2" gap-sm="3" nowrap>
        <MCol v-if="hero.project?.user?.image_url" class="shrink-0">
          <OwnerAvatar
            :alt="hero.project.user.name"
            :is-recommended-owner="hero.project.user.is_selected_user"
            size="32"
            sm="48"
            :src="hero.project.user.image_url"
          />
        </MCol>
        <MCol class="flex flex-col w-full">
          <MText
            max-lines="3"
            class="mb-3 sm:mb-4 last:mb-0"
            size="x-small"
            sm="large"
            tag="h3"
            weight="bold"
          >
            {{ hero.title }}
          </MText>
          <MRow align="center" class="mt-auto" justify="between">
            <MCol class="flex">
              <template v-if="hasPriceText">
                <!-- TODO: ABテスト終了後元に戻す -->
                <div class="px-2155-ab mt-auto">
                  <MText class="hidden" size="x-small" sm="small" weight="bold">
                    <span class="text-secondary">総額</span>
                    {{
                      hero.project?.collected_money?.toLocaleString('ja-JP', {
                        currency: 'JPY',
                        style: 'currency',
                      })
                    }}
                  </MText>
                  <MText
                    v-if="minPrice"
                    size="x-small"
                    sm="large"
                    weight="bold"
                  >
                    {{
                      minPrice.toLocaleString('ja-JP', {
                        currency: 'JPY',
                        style: 'currency',
                      })
                    }}〜
                  </MText>
                  <MText
                    v-else
                    color="secondary"
                    size="x-small"
                    sm="large"
                    weight="bold"
                  >
                    {{ RETURN_SUSPENDED_TEXT }}
                  </MText>
                </div>
              </template>
            </MCol>
            <MCol class="flex">
              <LikeButton
                :click-tracking-category="clickTrackingCategory"
                :click-tracking-label-module="clickEventLabelModule"
                :click-tracking-label-position="index"
                size="small"
                sm="large"
                :project-id="hero.project?.id"
            /></MCol>
          </MRow>
        </MCol>
      </MRow>
    </article>
  </a>
</template>
