<script setup>
import { computed } from 'vue';
import { MCol, MRow } from '@ca-crowdfunding/makuake-ui-n';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useLink from '@/composables/link';

const props = defineProps({
  clickTrackingCategory: {
    type: String,
    required: true,
  },
  clickTrackingLabel: {
    type: String,
    required: true,
  },
  href: {
    type: String,
    required: true,
  },
});

const { resolveLink } = useLink();
const target = computed(() => resolveLink(props.href).target);
</script>

<template>
  <li class="article-item mb-4 sm:mb-6 last:mb-0">
    <a
      class="block"
      :href="href"
      :data-click-tracking-category="clickTrackingCategory"
      :data-click-tracking-label="clickTrackingLabel"
      :target="target"
    >
      <article class="article-item">
        <MRow align="center" nowrap>
          <MCol>
            <ThumbnailCard aspect-ratio="1 / 1">
              <template #image>
                <slot name="image" />
              </template>
            </ThumbnailCard>
          </MCol>
          <MCol class="overflow-hidden">
            <slot />
          </MCol>
        </MRow>
      </article>
    </a>
  </li>
</template>

<style scoped>
.thumbnail-card {
  height: 80px;
  max-height: 25vw;
  max-width: 25vw;
  width: 80px;
}

.thumbnail-card :deep(img) {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

@media (min-width: 640px) {
  .thumbnail-card {
    height: 160px;
    width: 160px;
  }
}
</style>
