/** @deprecated Use `SORT_POPULAR` instead. */
export const SortKeyCollectedMoney = 'collected_money';
export const PER_PAGE = 15;
export const FILTER_ONGOING = 'onGoing';
export const FILTER_IN_STORE = 'inStore';
export const KIND_ONGOING = 'funded';
export const KIND_IN_STORE = 'store';
export const SORT_POPULAR = 'collected_money';
export const SORT_OPTION_KEY_NEW = 'new';
export const SORT_OPTION_KEY_POPULAR = 'popular';
/**
 * @typedef {Object} SortOption
 * @property {string} key
 * @property {string} label
 */
/**
 * @type {ReadonlyArray<SortOption>}
 */
export const SORT_OPTIONS = Object.freeze([
  { key: SORT_OPTION_KEY_NEW, label: '新着順' },
  { key: SORT_OPTION_KEY_POPULAR, label: '人気順' },
]);
/** @type {ReadonlyArray<SortOption>} */
export const NO_SORT_OPTIONS = Object.freeze([]);
