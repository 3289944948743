<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/home/fetchProjects';
import useIntersection from '@/composables/home/intersection';

const { fetchComingSoonProjects, isLoading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchComingSoonProjects);
</script>

<template>
  <ProjectSectionBase
    href="/discover/coming-soon"
    label="coming-soon"
    :loading="isLoading"
    :projects
    title="もうすぐ開始"
  />
</template>
