<script setup>
import { ref } from 'vue';
import {
  MAvatar,
  MCol,
  MImg,
  MRow,
  MText,
} from '@ca-crowdfunding/makuake-ui-n';
import ArticleItem from '@/components/home/ArticleItem';
import SectionHeader from '@/components/home/SectionHeader';
import useClickTracking from '@/composables/clickTracking';
import useIntersection from '@/composables/home/intersection';
import { ARTICLE_DISPLAY_NUMBER } from '@/consts/home';
import api from '@/modules/api/v2/communications';

const reports = ref([]);
const { eventCategories, makeEventLabel } = useClickTracking();
const { isDone, setupIntersection } = useIntersection();

const fetchActivityReports = async () => {
  const res = await api.fetchForTop({
    per_page: ARTICLE_DISPLAY_NUMBER,
    posted: 'owner',
  });
  if (res.data?.communications?.length) reports.value = res.data.communications;
};

setupIntersection(fetchActivityReports);
</script>

<template>
  <section v-if="reports.length || !isDone" class="activity-report-section">
    <SectionHeader
      :click-tracking-category="eventCategories.home"
      :click-tracking-label="makeEventLabel('activity_report', 'more')"
      href="/report"
      title="最新の活動レポート"
    />
    <ul>
      <ArticleItem
        v-for="(report, index) in reports"
        :key="index"
        :click-tracking-category="eventCategories.home"
        :click-tracking-label="makeEventLabel('activity_report', 'item', index)"
        :href="report.url"
      >
        <template #image>
          <MImg
            v-if="report.project.image_url"
            alt=""
            height-sm="90"
            height="45"
            :src="report.project.image_url"
            width-sm="160"
            width="80"
          />
        </template>
        <div class="mb-4">
          <MRow gap="2" nowrap>
            <MCol class="shrink-0">
              <MAvatar
                :alt="report.owner.name"
                size="16"
                sm="20"
                :src="report.owner.image_url"
              />
            </MCol>
            <MCol class="overflow-hidden">
              <MText
                max-lines="1"
                size="small"
                sm="large"
                tag="h4"
                weight="bold"
              >
                {{ report.title }}
              </MText>
            </MCol>
          </MRow>
        </div>
        <MText size="2x-small" sm="small" tag="h5" weight="bold">
          {{ report.project.title }}
        </MText>
      </ArticleItem>
    </ul>
  </section>
</template>
