<script setup>
import { ref } from 'vue';
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import SectionHeader from '@/components/home/SectionHeader';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useClickTracking from '@/composables/clickTracking';
import useLink from '@/composables/link';
import useRandomPicker from '@/composables/randomPicker';
import useIntersection from '@/composables/home/intersection';
import { EVENT_DISPLAY_NUMBER } from '@/consts/home';
import api from '@/modules/api/v2/contents';

const partners = ref([]);
const { eventCategories, makeEventLabel } = useClickTracking();
const { resolveLink } = useLink();
const { randomPick } = useRandomPicker();
const { isDone, setupIntersection } = useIntersection();

const fetchPartners = async () => {
  const res = await api.fetchAll({ per_page: EVENT_DISPLAY_NUMBER * 3 });
  if (res.data?.contents?.length)
    partners.value = randomPick(res.data.contents, EVENT_DISPLAY_NUMBER);
};

setupIntersection(fetchPartners);
</script>

<template>
  <aside v-if="partners.length || !isDone" class="event-section pb-10 sm:pb-14">
    <SectionHeader heading-tag="h2" title="取り組み・イベント" />
    <div class="max-w-4xl mx-auto">
      <MRow gap-sm="6" tag="ul">
        <MCol
          v-for="(partner, index) in partners"
          :key="index"
          cols="6"
          md="4"
          tag="li"
        >
          <a
            class="block pb-2 sm:pb-0"
            :href="partner.url"
            :data-click-tracking-category="eventCategories.home"
            :data-click-tracking-label="
              makeEventLabel('partner', 'item', index)
            "
            :target="resolveLink(partner.url).target"
          >
            <article>
              <ThumbnailCard
                alt=""
                aspect-ratio="16 / 9"
                class="mb-4"
                height-md="159"
                height-sm="246"
                height="162"
                :src="partner.image_url"
                width-md="283"
                width-sm="436"
                width="288"
              />
              <MText
                max-lines="3"
                size="x-small"
                sm="small"
                tag="h3"
                weight="bold"
              >
                {{ partner.title }}
              </MText>
            </article>
          </a>
        </MCol>
      </MRow>
    </div>
  </aside>
</template>

<style scoped>
.max-w-4xl {
  max-width: 56rem;
}
</style>
