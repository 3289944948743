<script setup>
import { inject, computed } from 'vue';
import { MCol, MRow } from '@ca-crowdfunding/makuake-ui-n';
import useClickTracking from '@/composables/clickTracking';
import useEnvDefinition from '@/composables/envDefinition';

const { eventCategories, makeEventLabel } = useClickTracking();
const { env, isProduction } = useEnvDefinition();

const cdn = env.URL_MAKUAKE_CDN;
const device = inject('device');
const prefix = computed(() =>
  ['sp', 'app'].includes(device.value) ? 'sp' : 'web',
);

const href = computed(() =>
  isProduction
    ? `https://lp-mk-2.makuake.com?utm_medium=referral&utm_source=makuake.com&utm_campaign=${prefix.value}_banner_mk`
    : '/apply/form',
);
</script>

<template>
  <section class="application-section pb-10 sm:pb-14">
    <MRow justify="center">
      <MCol>
        <a
          class="block"
          :href="href"
          :data-click-tracking-category="eventCategories.home"
          :data-click-tracking-label="makeEventLabel('banners', 'item', 1)"
        >
          <h2>
            <picture>
              <source
                height="160"
                media="(min-width: 640px)"
                :srcset="`${cdn}img/home/bn_keisai_pc.webp 1x, ${cdn}img/home/bn_keisai_pc@2x.webp 2x`"
                width="588"
              />
              <img
                alt="掲載希望の方はこちら"
                height="124"
                loading="lazy"
                :src="`${cdn}img/home/bn_keisai_sp.webp`"
                width="366"
              />
            </picture>
          </h2>
        </a>
      </MCol>
    </MRow>
  </section>
</template>
