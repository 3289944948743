import { ref } from 'vue';
import useRandomPicker from '@/composables/randomPicker';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';
import RANKING_CATEGORIES from '@/consts/ranking';
import api from '@/modules/api/v2/projects';
import rankApi from '@/modules/api/v2/rankings';
import returnApi from '@/modules/api/v2/returns';
import remindApi from '@/modules/api/php/top/projectend';
import recommendApi from '@/modules/api/php/recommended';
import watchApi from '@/modules/api/php/top/watched';
import loggedInStatus from '@/modules/isLoggedinStatus';
import watchedProjects from '@/modules/watchedProjects';

export default function useFetchProjects() {
  // スケルトンローディングのために初期値を設定
  const projects = ref(Array(PROJECT_DISPLAY_NUMBER));
  const isLoading = ref(true);

  const userId = ref();

  const { randomPick } = useRandomPicker();

  const fn = async action => {
    isLoading.value = true;
    try {
      await action();
    } catch (error) {
      projects.value = [];
    } finally {
      isLoading.value = false;
    }
  };

  const fetchWatchedProjects = async () => {
    await fn(async () => {
      const watchedIds = watchedProjects.listIds();

      if (!watchedIds.length) {
        throw Error;
      }

      const res = await watchApi.fetchProjectsWatched(watchedIds);
      projects.value = res.data?.length ? res.data : [];
    });
  };

  const fetchRemindedProjects = async () => {
    await fn(async () => {
      const userLoggedIn = loggedInStatus.isUserLoggedin();

      if (!userLoggedIn) {
        throw Error;
      }

      const res = await remindApi.fetchProjectsEnd();
      projects.value = res.data?.projects?.length ? res.data.projects : [];
    });
  };

  const fetchRecommendedProjects = async () => {
    await fn(async () => {
      const viewedProjectIds = watchedProjects.listIds().join();
      const res = await recommendApi.fetchRecommended({
        viewedProjectIds,
        withReturns: true,
        withUser: true,
      });
      projects.value = res.data?.projects?.length ? res.data.projects : [];
      userId.value = res.data?.recommend_user_id;
    });
  };

  const fetchPickedUpProjects = async () => {
    await fn(async () => {
      const res = await api.fetchPickup({
        per_page: PROJECT_DISPLAY_NUMBER,
        sort: 'random',
        with_returns: true,
        with_user: true,
      });
      projects.value = res.data?.projects?.length ? res.data.projects : [];
    });
  };

  /** @param {string} tagGroup */
  const fetchRankedProjects = async (
    tagGroup = RANKING_CATEGORIES[0].value,
  ) => {
    await fn(async () => {
      const res = await rankApi.fetchRanking({
        tag_group: tagGroup,
        with_returns: true,
        with_user: true,
      });
      projects.value = res.data?.rankings?.length
        ? res.data.rankings.reduce((acc, cur) => {
            const { project } = cur;
            project.rank = cur.rank;
            acc.push(project);
            return acc;
          }, [])
        : [];
    });
  };

  const fetchNewProjects = async () => {
    await fn(async () => {
      const res = await api.fetchNew({
        per_page: PROJECT_DISPLAY_NUMBER * 5,
        with_returns: true,
        with_user: true,
      });
      projects.value = res.data?.projects?.length
        ? randomPick(res.data.projects, PROJECT_DISPLAY_NUMBER)
        : [];
    });
  };

  const fetchComingSoonProjects = async () => {
    await fn(async () => {
      const res = await api.fetchComingSoon({
        per_page: PROJECT_DISPLAY_NUMBER * 5,
        with_user: true,
      });
      projects.value = res.data?.projects?.length
        ? randomPick(res.data.projects, PROJECT_DISPLAY_NUMBER)
        : [];
    });
  };

  const fetchPopularProjects = async () => {
    await fn(async () => {
      const res = await returnApi.fetchPopular({
        per_page: PROJECT_DISPLAY_NUMBER,
        with_returns: true,
        with_user: true,
      });
      projects.value = res.data?.returns?.length
        ? res.data.returns.reduce((acc, cur) => {
            // プロジェクトの重複を防ぐ
            if (!acc.some(project => project.id === cur.project.id)) {
              acc.push(cur.project);
            }
            return acc;
          }, [])
        : [];
    });
  };

  /** @param {number|number[]} tagIds  */
  const fetchProjectsByTagIds = async tagIds => {
    await fn(async () => {
      const res = await api.fetchProjectsByTagIds({
        is_ongoing: true,
        per_page: PROJECT_DISPLAY_NUMBER,
        page: 1,
        tag_ids: Array.isArray(tagIds) ? tagIds.join() : tagIds,
        with_returns: true,
        with_user: true,
      });
      projects.value = res.data?.projects?.length ? res.data.projects : [];
    });
  };

  return {
    fetchComingSoonProjects,
    fetchNewProjects,
    fetchPickedUpProjects,
    fetchPopularProjects,
    fetchProjectsByTagIds,
    fetchRankedProjects,
    fetchRecommendedProjects,
    fetchRemindedProjects,
    fetchWatchedProjects,
    isLoading,
    projects,
    userId,
  };
}
