import { inject, ref, watch } from 'vue';
import likeJson from '@/assets/top/like';
import {
  LIKED_PROJECT_IDS_KEY,
  IS_LIKED_PROJECT_IDS_LOADED_KEY,
} from '@/consts/home/likedProjectIds';
import api from '@/modules/api/php/good';
import loginStatus from '@/modules/isLoggedinStatus';

export default function useLikeStateManager() {
  const lastFrame = likeJson.op;

  /** lottie制御用 */
  const lottieRef = ref(null);

  /** 気になるの表示状態 */
  const isLiked = ref(false);

  /** 気になるの登録状態 */
  const isLikedOnServer = ref(false);

  const likedIds = inject(
    LIKED_PROJECT_IDS_KEY,
    ref(/** @type {number[]} */ ([])),
  );
  const isLoaded = inject(IS_LIKED_PROJECT_IDS_LOADED_KEY, ref(false));

  /** snackbar表示  */
  const snackbar = inject('snackbar');
  const snackbarMessage = inject('snackbarMessage');
  const snackbarActionLabel = inject('snackbarActionLabel');
  const snackbarAction = inject('snackbarAction');

  /**
   * likedIdsの取得が終わってから、指定したIDが含まれているかどうか判定する
   *
   * @param {number|`${number}`} projectId 対象プロジェクトのID
   * @returns {Promise<boolean>}
   */
  const checkIsLiked = projectId => {
    const checkIsLoaded = () =>
      new Promise(resolve => {
        if (isLoaded.value) {
          resolve(likedIds.value.includes(Number(projectId)));
        } else {
          setTimeout(() => resolve(checkIsLoaded()), 500);
        }
      });
    return checkIsLoaded();
  };

  const initState = async projectId => {
    const initialValue = await checkIsLiked(projectId);
    isLikedOnServer.value = initialValue;
    isLiked.value = initialValue;
    if (initialValue) {
      lottieRef.value.goToAndStop(lastFrame, true);
    }
  };

  /**
   * 「気になる」状態を保存する
   *
   * @param {number|`${number}`} projectId 対象プロジェクトのID
   */
  const saveData = async projectId => {
    const isLoggedIn = loginStatus.isUserLoggedin();

    if (isLoggedIn) {
      if (isLiked.value) {
        await api.registerGood(projectId);
        likedIds.value.push(Number(projectId));
      } else {
        await api.unregisterGood(projectId);
        likedIds.value = likedIds.value.filter(id => id !== Number(projectId));
      }
    } else if (isLiked.value) {
      api.addIdToCookie(projectId);
      likedIds.value.push(Number(projectId));
    } else {
      api.removeIdFromCookie(projectId);
      likedIds.value = likedIds.value.filter(id => id !== Number(projectId));
    }
  };

  let timeoutId;

  const debounce = (fn, delay) => () =>
    new Promise((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn().then(resolve).catch(reject);
      }, delay);
    });

  /**
   * 気になるボタンをクリックしたときの一連の処理
   *
   * @param {number|`${number}`} projectId 対象プロジェクトのID
   * @param {{
   * onSuccess?: (isLiked: bool) => void;
   * }} [options={}]
   */
  const handleClick = async (projectId, { onSuccess } = {}) => {
    isLiked.value = !isLiked.value;

    try {
      const func = debounce(() => saveData(projectId), 500);
      await func();
      isLikedOnServer.value = isLiked.value;
      onSuccess?.(isLiked.value);
    } catch (e) {
      // エラーが起きた場合は状態を元に戻す
      isLiked.value = isLikedOnServer.value;

      if (isLiked.value) {
        lottieRef.value.goToAndStop(lastFrame, true);
      }

      if (e instanceof api.LimitExceededError) {
        // TODO: refactor
        snackbar.value = true;
        snackbarMessage.value = '11件以上の登録にはログインが必要です';
        snackbarActionLabel.value = 'ログイン';
        snackbarAction.value = () => {
          window.location.href = `/login/?ref=${encodeURIComponent(window.location.href)}`;
        };
      } else {
        // TODO: refactor
        snackbar.value = true;
        snackbarMessage.value = '通信エラーが起きました';
        snackbarActionLabel.value = '';
        snackbarAction.value = () => {};
      }
    }
  };

  watch(isLiked, newValue => {
    if (newValue) {
      lottieRef.value.play();
    } else {
      lottieRef.value.stop();
    }
  });

  return {
    handleClick,
    initState,
    likeJson,
    lottieRef,
  };
}
