<script setup>
import { ref } from 'vue';

const container = ref(null);

defineProps({
  gap: {
    type: [String, Number],
    default: 3,
  },
  gapSm: {
    type: [String, Number],
    default: 6,
  },
  tag: {
    type: String,
    default: 'div',
  },
});

defineExpose({ container });
</script>

<template>
  <div class="scroll-container overflow-hidden -mx-6 lg:mx-0">
    <Component :is="tag" ref="container" class="flex px-6 lg:px-0">
      <slot />
    </Component>
  </div>
</template>

<style lang="scss" scoped>
.scroll-container > * {
  margin: calc(-0.125rem * v-bind(gap));
  overflow-x: auto;
  scroll-padding: 0 1.5rem;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > :deep(*) {
    padding: calc(0.125rem * v-bind(gap));
    scroll-snap-align: start;
  }
}

@media (min-width: 640px) {
  .scroll-container > * {
    margin: calc(-0.125rem * v-bind(gapSm));

    & > :deep(*) {
      padding: calc(0.125rem * v-bind(gapSm));
    }
  }
}

@media (min-width: 1248px) {
  .scroll-container > * {
    scroll-padding: 0;
  }
}
</style>
