import client from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

const fetchProjectsWatched = projectIds => {
  const url = '/watched';
  return client.phpApiClient
    .get(buildUrl(url, { project_ids: projectIds }))
    .then(response => response)
    .catch(error => error);
};

export default {
  fetchProjectsWatched,
};
