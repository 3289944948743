import { getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';

export default function useIntersection() {
  const isDone = ref(false);

  const setupIntersection = callback => {
    const io = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        if (entry.isIntersecting) {
          await callback();
          isDone.value = true;
          io.unobserve(entry.target); // 一度だけ実行するために観察を停止
        }
      });
    });

    onMounted(() => {
      const el = getCurrentInstance()?.vnode?.el;

      if (el instanceof Element) {
        io.observe(el);
        onUnmounted(() => io.unobserve(el));
      }
    });
  };

  return {
    isDone,
    setupIntersection,
  };
}
