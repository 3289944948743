<script setup>
import { computed, watch } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import useClickTracking from '@/composables/clickTracking';
import useLikeStateManager from '@/composables/home/likeStateManager';

const props = defineProps({
  clickTrackingCategory: {
    type: String,
    required: true,
  },
  clickTrackingLabelModule: {
    type: String,
    required: true,
  },
  clickTrackingLabelPosition: {
    type: Number,
    required: true,
  },
  projectId: {
    type: Number,
    required: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
  sm: {
    type: String,
    default: '',
  },
});

const { pushGtmClickEvent, makeEventLabel } = useClickTracking();

const { handleClick, initState, likeJson, lottieRef } = useLikeStateManager();

watch(
  () => props.projectId,
  () => initState(props.projectId),
  { immediate: true },
);

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'small':
      return ['h-9', 'w-9'];
    case 'medium':
      return ['h-12', 'w-12'];
    case 'large':
      return ['h-14', 'w-14'];
    default:
      return ['h-12', 'w-12'];
  }
});

const sizeSmClass = computed(() => {
  switch (props.sm) {
    case 'small':
      return 'sm-hw-9';
    case 'medium':
      return 'sm-hw-12';
    case 'large':
      return 'sm-hw-14';
    default:
      return '';
  }
});

const sizeWrapperClasses = computed(() => {
  switch (props.size) {
    case 'small':
      return ['hw-4.5'];
    case 'medium':
      return ['h-6', 'w-6'];
    case 'large':
      return ['h-7', 'w-7'];
    default:
      return ['h-6', 'w-6'];
  }
});

const sizeSmWrapperClass = computed(() => {
  switch (props.sm) {
    case 'small':
      return 'sm-hw-4.5';
    case 'medium':
      return 'sm-hw-6';
    case 'large':
      return 'sm-hw-7';
    default:
      return '';
  }
});

const classNames = computed(() => [...sizeClasses.value, sizeSmClass.value]);
const wrapperClassNames = computed(() => [
  ...sizeWrapperClasses.value,
  sizeSmWrapperClass.value,
]);

/**
 * 気になるボタンのクリックイベントハンドラーに渡すコールバック関数（成功時）
 *
 * @param {boolean} isLiked - 成功後の気になる状態（true: 気になる登録された, false: 気になる解除された）
 */
const onSuccess = isLiked => {
  // 成功後の気になる状態に応じて、 like unlike どちらがクリックされたかを判定
  const parts = isLiked ? 'like_button' : 'unlike_button';
  const eventLabel = makeEventLabel(
    props.clickTrackingLabelModule,
    parts,
    props.clickTrackingLabelPosition,
  );
  pushGtmClickEvent(props.clickTrackingCategory, eventLabel);
};
</script>

<template>
  <div
    class="m-icon inline-flex align-middle relative"
    :class="wrapperClassNames"
  >
    <div
      class="centered-absolute h-10 w-10"
      @click.stop.prevent="handleClick(projectId, { onSuccess })"
    >
      <Vue3Lottie
        ref="lottieRef"
        class="centered-absolute pointer-events-none"
        :animation-data="likeJson"
        :auto-play="false"
        :class="classNames"
        :loop="false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.centered-absolute {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hw-4\.5 {
  height: 1.125rem;
  width: 1.125rem;
}

@media (min-width: 640px) {
  .sm-hw-4\.5 {
    height: 1.125rem;
    width: 1.125rem;
  }

  .sm-hw-6 {
    height: 1.5rem;
    width: 1.5rem;
  }

  .sm-hw-7 {
    height: 1.75rem;
    width: 1.75rem;
  }

  .sm-hw-9 {
    height: 2.25rem;
    width: 2.25rem;
  }

  .sm-hw-12 {
    height: 3rem;
    width: 3rem;
  }

  .sm-hw-14 {
    height: 3.5rem;
    width: 3.5rem;
  }
}
</style>
