<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/home/fetchProjects';
import useIntersection from '@/composables/home/intersection';

const { fetchNewProjects, isLoading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchNewProjects);
</script>

<template>
  <ProjectSectionBase
    href="/discover/new"
    label="new"
    :loading="isLoading"
    :projects
    title="新着のプロジェクト"
  />
</template>
