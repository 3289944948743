<script setup>
import { ref } from 'vue';
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import ArticleItem from '@/components/home/ArticleItem';
import SectionHeader from '@/components/home/SectionHeader';
import useClickTracking from '@/composables/clickTracking';
import useIntersection from '@/composables/home/intersection';
import api from '@/modules/api/php/blog';

const articles = ref([]);
const { eventCategories, makeEventLabel } = useClickTracking();
const { isDone, setupIntersection } = useIntersection();

// noteの画像CDNについているクエリパラメータを書き換えてこちらの表示サイズに合わせる
const createImageSrc = (src, width) =>
  String(src).replace(/width=[0-9]*/, `width=${width}`);

// 日付フォーマットをHTML5のdatetimeに合わせる
const createDatetime = date => String(date).replace(/\./g, '-');

const fetchBlogArticles = async () => {
  const res = await api.fetchArticles();
  if (res.data?.articles?.length) articles.value = res.data.articles;
};

setupIntersection(fetchBlogArticles);
</script>

<template>
  <aside v-if="articles.length || !isDone" class="blog-section">
    <SectionHeader
      :click-tracking-category="eventCategories.home"
      :click-tracking-label="makeEventLabel('blog_article', 'more')"
      heading-tag="h2"
      href="https://note.com/makuake_note"
      title="Makuakeブログ"
    />
    <ul>
      <ArticleItem
        v-for="(article, index) in articles"
        :key="index"
        :click-tracking-category="eventCategories.home"
        :click-tracking-label="makeEventLabel('blog_article', 'item', index)"
        :href="article.link"
      >
        <template v-if="article.image_url" #image>
          <picture>
            <source
              height="90"
              media="(min-width: 640px)"
              :srcset="`${createImageSrc(article.image_url, 160)} 1x, ${createImageSrc(article.image_url, 320)} 2x`"
              width="160"
            />
            <img
              alt=""
              height="45"
              loading="lazy"
              :src="createImageSrc(article.image_url, 160)"
              width="80"
            />
          </picture>
        </template>
        <MText
          class="mb-4 sm:mb-6"
          size="2x-small"
          sm="medium"
          tag="h3"
          weight="bold"
        >
          {{ article.title }}
        </MText>
        <MText color="secondary" size="3x-small" sm="small">
          <time :datetime="createDatetime(article.published_date)">{{
            article.published_date
          }}</time>
        </MText>
      </ArticleItem>
    </ul>
  </aside>
</template>

<style scoped>
img {
  height: auto;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
</style>
