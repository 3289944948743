<script setup>
import { onMounted, ref } from 'vue';
import { MSelect, MText } from '@ca-crowdfunding/makuake-ui-n';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/home/fetchProjects';
import useIntersection from '@/composables/home/intersection';
import RANKING_CATEGORIES from '@/consts/ranking';

const container = ref(null);
const selectedValue = ref(RANKING_CATEGORIES[0].value);

const { fetchRankedProjects, isLoading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

const fetchProjects = async () => {
  await fetchRankedProjects(selectedValue.value);
  container.value?.scrollTo(0, 0);
};

setupIntersection(fetchRankedProjects);

onMounted(() => {
  container.value = document.querySelector('.ranking-section ol');
});
</script>

<template>
  <ProjectSectionBase
    container-tag="ol"
    href="/discover/ranking"
    label="ranking"
    :loading="isLoading"
    :projects
    show
    title="今日のランキング"
  >
    <template #[`title.append`]>
      <MSelect
        v-model="selectedValue"
        aria-label="グループの選択"
        class="font-bold"
        density-sm="compact"
        :options="RANKING_CATEGORIES"
        size="small"
        sm="medium"
        variant="tonal"
        @change="fetchProjects"
      />
    </template>
    <template #[`project.thumbnail.append`]="{ project }">
      <div
        v-if="project?.rank"
        :class="`rank-box rank-${project.rank} flex items-center justify-center`"
      >
        <MText
          color="white"
          size="3x-small"
          sm="x-small"
          tag="span"
          weight="bold"
          >{{ project.rank }}</MText
        >
      </div>
    </template>
  </ProjectSectionBase>
</template>

<style scoped>
.rank-box {
  background: rgb(34 34 34 / 88%);
  height: 1.5rem;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1.5rem;
}

.rank-1 {
  background: rgb(234 204 48 / 88%);
}

.rank-2 {
  background: rgb(162 176 185 / 88%);
}

.rank-3 {
  background: rgb(230 144 108 / 88%);
}

@media (min-width: 640px) {
  .rank-box {
    height: 2rem;
    width: 2rem;
  }
}
</style>
