import { computed, onBeforeMount } from 'vue';
import UA from '@/modules/UserAgent';

/**
 * @typedef {{
 * module: string;
 * parts: string;
 * position?: number;
 * }} EventLabel
 */

let device = null;

export default function useClickTracking() {
  const setDevice = () => {
    const userAgent = new UA();

    if (userAgent.isSP()) {
      device = 'sp';
      return;
    }

    if (userAgent.isNative()) {
      device = 'app';
      return;
    }

    device = 'pc';
  };

  const makeEventCategory = page => JSON.stringify({ device, page });

  const eventCategories = computed(() => ({
    discover: makeEventCategory('discover'),
    favorite: makeEventCategory('favorite'),
    home: makeEventCategory('top'),
    member: makeEventCategory('member'),
    project: makeEventCategory('project'),
    ticket: makeEventCategory('ticket'),
  }));

  /**
   * イベントラベルを作成する
   *
   * @param {string} module
   * @param {string} [parts='']
   * @param {number|null} [position=null]
   * @returns {string}
   */
  const makeEventLabel = (module, parts = '', position = null) => {
    /** @type {EventLabel} */
    const label = {
      module,
      parts,
    };

    if (position != null) {
      label.position = position;
    }

    return JSON.stringify(label);
  };

  /**
   * makeEventLabelで作成したJSON文字列をパースする
   *
   * @param {string} label
   * @returns {EventLabel}
   */
  const parseEventLabel = label => {
    try {
      return JSON.parse(label);
    } catch (e) {
      return { module: '', parts: '' };
    }
  };

  const pushGtmClickEvent = (eventCategory, eventLabel) => {
    if (!eventCategory) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click_tracking',
      event_category: eventCategory,
      event_action: 'click',
      event_label: eventLabel,
    });
  };

  onBeforeMount(() => {
    if (device) return;
    setDevice();
  });

  return {
    eventCategories,
    makeEventLabel,
    parseEventLabel,
    pushGtmClickEvent,
  };
}
