<script setup>
import { MChip, MCol, MIcon, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import ScrollContainer from '@/components/home/ScrollContainer';
import useClickTracking from '@/composables/clickTracking';

const categories = [
  { label: 'プロダクト', slug: 'product' },
  { label: 'ファッション', slug: 'fashion' },
  { label: 'フード', slug: 'food' },
  { label: 'レストラン・バー', slug: 'restaurant' },
];

const { eventCategories, makeEventLabel } = useClickTracking();
</script>

<template>
  <section class="category-section pb-10 md:pb-14">
    <MRow align="center" gap-md="8" justify-md="center">
      <MCol>
        <MText class="break-phrase" size="x-large" tag="h3" weight="bold">
          人気のカテゴリーから探す
        </MText>
      </MCol>
      <MCol class="max-w-full">
        <ScrollContainer gap="2" gap-sm="4" tag="ul">
          <MCol v-for="(category, index) in categories" :key="index" tag="li">
            <a
              class="flex rounded-full"
              :href="`/discover/categories/${category.slug}/?sort=popular`"
              :data-click-tracking-category="eventCategories.home"
              :data-click-tracking-label="
                makeEventLabel('category_popular', 'item', index)
              "
            >
              <MChip size="x-large" variant="outlined">
                <template #label>
                  <MText
                    class="truncate"
                    size="x-small"
                    sm="small"
                    weight="bold"
                    tag="span"
                  >
                    <span class="flex items-center">
                      {{ category.label }}
                      <MIcon name="right" />
                    </span>
                  </MText>
                </template>
              </MChip>
            </a>
          </MCol>
        </ScrollContainer>
      </MCol>
    </MRow>
  </section>
</template>

<style scoped>
.break-phrase {
  word-break: auto-phrase;
}
</style>
