import { computed, ref, toValue, watchEffect } from 'vue';

export default function useReturnPrice(projectReturns) {
  const minPrice = ref();

  const calcPrice = () => {
    const returns = toValue(projectReturns);
    // リターンがない場合はundefinedにする
    if (!returns?.length) {
      minPrice.value = undefined;
      return;
    }

    const validReturns = returns.filter(
      r =>
        r.can_purchase &&
        (!r.limit_amount || r.limit_amount - r.supported_amount),
    );
    // 有効なリターンがない場合は0にする
    if (!validReturns.length) {
      minPrice.value = 0;
      return;
    }

    // 有効なリターンの中で最安値を設定する
    minPrice.value = validReturns.reduce(
      (min, r) => (r.price < min ? r.price : min),
      validReturns[0].price,
    );
  };

  watchEffect(() => {
    calcPrice();
  });

  const hasPriceText = computed(() => minPrice.value !== undefined);

  return { hasPriceText, minPrice };
}
