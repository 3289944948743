<script setup>
import { computed } from 'vue';
import { MButton, MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import useLink from '@/composables/link';
import useScrollContainer from '@/composables/common/scrollContainer';

const props = defineProps({
  clickTrackingCategory: {
    type: String,
  },
  clickTrackingLabel: {
    type: String,
  },
  headingTag: {
    type: String,
    default: 'h3',
  },
  href: {
    type: String,
  },
  scrollContainer: {
    type: Object,
  },
  title: {
    type: String,
  },
});

const { resolveLink } = useLink();

const container = computed(() => props.scrollContainer?.container);

const {
  isLeftScrollDisabled,
  isRightScrollDisabled,
  isScrollable,
  scrollToLeft,
  scrollToRight,
} = useScrollContainer(container);
</script>

<template>
  <header class="section-header pb-4 md:pb-6">
    <MRow align="center" justify="between" nowrap>
      <MCol>
        <MRow align="center" gap-md="6">
          <MCol>
            <slot name="title">
              <MText
                class="break-phrase"
                md="3x-large"
                size="x-large"
                :tag="headingTag"
                weight="bold"
                >{{ title }}</MText
              >
            </slot>
          </MCol>
          <MCol v-if="$slots.default">
            <slot />
          </MCol>
        </MRow>
      </MCol>
      <MCol v-if="href || isScrollable" :class="{ 'hidden md:block': !href }">
        <MRow align="center" gap="6" nowrap>
          <MCol v-if="href">
            <a
              class="hidden sm:flex text-xl"
              :data-click-tracking-category="clickTrackingCategory"
              :data-click-tracking-label="clickTrackingLabel"
              :href="href"
              :target="resolveLink(href).target"
            >
              <MText tag="span" weight="bold">すべて見る</MText>
            </a>
            <div class="sm:hidden -m-3">
              <MButton
                :data-click-tracking-category="clickTrackingCategory"
                :data-click-tracking-label="clickTrackingLabel"
                :href="href"
                icon="right"
                variant="plain"
              />
            </div>
          </MCol>
          <MCol v-if="isScrollable" class="hidden md:block">
            <MRow align="center" gap="2" nowrap>
              <MCol class="flex -mx-1 -my-4">
                <MButton
                  aria-label="左にスクロール"
                  class="scroll-button"
                  :disabled="isLeftScrollDisabled"
                  icon="leftCircle"
                  size="large"
                  variant="plain"
                  @click="scrollToLeft"
                />
              </MCol>
              <MCol class="flex -mx-1 -my-4">
                <MButton
                  aria-label="右にスクロール"
                  class="scroll-button"
                  :disabled="isRightScrollDisabled"
                  icon="rightCircle"
                  size="large"
                  variant="plain"
                  @click="scrollToRight"
                />
              </MCol>
            </MRow>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </header>
</template>

<style scoped>
.scroll-button {
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.break-phrase {
  word-break: auto-phrase;
}
</style>
