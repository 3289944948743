<script setup>
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import SectionHeader from '@/components/home/SectionHeader';
import useClickTracking from '@/composables/clickTracking';
import useEnvDefinition from '@/composables/envDefinition';

const { eventCategories, makeEventLabel } = useClickTracking();
const { env } = useEnvDefinition();

const cdn = env.URL_MAKUAKE_CDN;
</script>

<template>
  <section class="introduction-section pb-10 sm:pb-12">
    <SectionHeader heading-tag="h2" title="Makuakeの紹介" />
    <MRow align="center" gap-sm="6" justify="center">
      <MCol class="max-w-full shrink-0">
        <MText size="small" sm="medium">
          <span
            >Makuakeはまだ世の中にないものやストーリーあふれるチャレンジが<br
              class="hidden sm:inline"
            />集まる「<strong>アタラシイものや体験の応援購入サービス</strong>」です。</span
          >
        </MText>
      </MCol>
      <MCol>
        <a
          class="block"
          href="/guide"
          :data-click-tracking-category="eventCategories.home"
          :data-click-tracking-label="makeEventLabel('banners', 'item', 0)"
        >
          <h3>
            <picture>
              <source
                height="160"
                media="(min-width: 640px)"
                :srcset="`${cdn}img/home/bn_guide_pc.webp 1x, ${cdn}img/home/bn_guide_pc@2x.webp 2x`"
                width="588"
              />
              <img
                alt="Makuakeはじめてガイド"
                class="w-full"
                height="124"
                loading="lazy"
                :src="`${cdn}img/home/bn_guide_sp.webp`"
                width="366"
              />
            </picture>
          </h3>
        </a>
      </MCol>
    </MRow>
  </section>
</template>
